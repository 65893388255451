import React from 'react';
import styled from 'styled-components';
import SocialShare from './SocialShare';
import { Link } from 'gatsby';

import { COLORS } from '../constants/style';

const Footer = styled.section`
  .bottom-line {
    margin-top: 5em;
    text-align: right;
    border-bottom: solid 1px ${COLORS.textLightGray};
  }

  .links {
    text-align: left;
    margin-top: 0.5em;
    font-size: 0.8em;
    a {
      color: ${COLORS.textLightGray};
    }

    .divider {
      color: ${COLORS.textLightGray};
      margin: 0 0.7em;
    }
  }
`;

const BlogFooter = ({ url }) => {
  return (
    <Footer>
      <div className="bottom-line">
        <SocialShare url={url} className="mb-2" />
      </div>
      <div className="links">
        <Link to="/content#student-success">Student Success</Link>
        <span className="divider">|</span>
        <Link to="/content#insight-videos">Insight Videos</Link>
        <span className="divider">|</span>
        <Link to="/content#edcellent-insights">Edcellent Insights</Link>
      </div>
    </Footer>
  );
};

export default BlogFooter;
