import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import moment from 'moment';

import { blogPostTypes } from '../types/propTypes';
import SEO from '../components/SEO';
import { COLORS } from '../constants/style';
import PosedDiv from '../components/PosedDiv';
import SocialShare from '../components/SocialShare';
import BlogFooter from '../components/BlogFooter';

const BlogPostContainer = styled.div`
  width: 95%;
  max-width: 700px;

  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Header = styled.header`
  text-align: center;
  padding-top: 150px;

  .student-desc p {
    margin-bottom: 0.5em;
    font-style: italic;
    color: ${COLORS.textGray};
  }

  h1 {
    font-weight: 900;
    color: ${COLORS.textGray};
    margin: 0 auto 0.2em auto;
  }

  .blog-date {
    color: ${COLORS.darkGold};
    margin-bottom: 1.5em;
  }

  .featured-img {
    max-width: 100%;
    max-height: 500px;
    display: block;
    margin: 1.5em auto;
    filter: brightness(0.95);
  }
`;

const Article = styled.article`
  padding-top: 30px;
  padding-bottom: 200px;
  margin: auto;
  text-align: justify;
`;

const BlogPost = ({ data, location }) => {
  const {
    shortDescription,
    title,
    content,
    featuredImage,
    seoMetaTags,
  } = data.blog;

  return (
    <>
      <SEO meta={seoMetaTags} />
      <BlogPostContainer>
        <Header>
          <h1 className="text-left">Word of Advice from {title}</h1>
          {shortDescription && (
            <div
              className="text-right student-desc"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          )}
          <br />
          <PosedDiv>
            <SocialShare url={location.href} />
            <img
              className="featured-img"
              {...featuredImage.sizes}
              alt="featured banner"
            />
          </PosedDiv>
        </Header>
        <Article>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <BlogFooter url={location.href} />
        </Article>
      </BlogPostContainer>
    </>
  );
};

export const blogQuery = graphql`
  query($slug: String!) {
    blog: datoCmsStudentSuccess(slug: { eq: $slug }) {
      id
      shortDescription
      title
      content
      meta {
        updatedAt
      }
      featuredImage {
        sizes {
          src
          srcSet
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content
    }
  }
`;

BlogPost.propTypes = blogPostTypes;

export default BlogPost;
